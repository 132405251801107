import React, { PureComponent } from 'react';
import { message, Form, Select, Input } from 'antd';
import BigNumber from "bignumber.js";
import { FormInstance } from 'antd/lib/form';
import { Dialog, InputNumber, Layout, Button, EditTable } from '@/components';
import StockProductSelectDialog from './StockProductSelectDialog';
import { LibIStockDetail } from 'lib/interface/StockInterface';
import WebApi from '@/web/api';

interface IState {
  loading: boolean;
  visible: boolean;
  supplierOptions: {label: string; value: string}[];
  selectedProductList: LibIStockDetail[];
  supplierId: string;
  supplierName: string;
  products: any[];
}
export default class PurchaseReturnCreatePage extends PureComponent<RouterPropsType, IState> {

  private form: FormInstance | null = null

  data = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      supplierOptions: [],
      selectedProductList: [],
      supplierId: '',
      supplierName: '',
      products: [],
    };
  }

  componentDidMount() {
    WebApi.supplier_list().then((data: any) => {
      this.setState({
        supplierOptions: data.map(item => {
          return {
            label: item.supplierName,
            value: item.supplierId,
          };
        }),
      });
    })
  }

  render() {
    const { selectedProductList, supplierId, supplierName, supplierOptions } = this.state;
    return (<Layout.Page>
      <Layout flex1 scrollY>
        <Layout.Title>订单信息</Layout.Title>
        <Form ref={r => this.form = r}>
          <Form.Item label="供应商" name="supplierName" style={{width: 450}} labelCol={{span: 4}} rules={[{
              required: true,
              message: '请选择供应商',
            }]}>
              <Select labelInValue placeholder="选择供应商" onChange={(value: any) => {
                this.setState({
                  supplierName: value.label,
                  supplierId: value.value,
                  selectedProductList: [],
                });
              }} options={supplierOptions} ></Select>
            </Form.Item>
            <Form.Item name="remark" label="备注说明" style={{width: 900}} labelCol={{span: 2}}>
              <Input placeholder="填写备注" className="underline"/>
            </Form.Item>
        </Form>

          <Layout.Title icon="icon-form-shangpin">商品明细</Layout.Title>
          <EditTable dataSource={selectedProductList}
            onAdd={(record, index) => {
              if (this.state.supplierId) {
                this.setState({
                  visible: true,
                });
              } else {
                this.form?.validateFields();
              }
            }}
            onMinus={(record, index) => {
              const next = [...this.state.products];
              next.splice(index, 1);
              this.setState({
                products: next
              });
            }}
            rowKey={r => r.id || r.stockId}
            columns={
            [
              {
                title: '商品',
                dataIndex: 'productName',
                width: 200,
              },
              {
                title: '规格',
                dataIndex: 'skuName',
                width: 300,
              },
              {
                title: '进货价格',
                align: 'right',
                dataIndex: 'price',
                width: 150,
                render: (v, row) => {
                  if (!row.stockId) return null;
                  return v || 0;
                },
              },
              {
                title: '仓库',
                dataIndex: 'repositoryName',
                width: 150,
              },
              {
                title: '库存',
                align: 'right',
                dataIndex: 'stockCount',
                width: 150,
              },
              {
                title: '退货价格',
                align: 'right',
                dataIndex: 'returnPrice',
                width: 150,
                render: (v, r, index) => {
                  if (!r.skuId) return null;
                  return <InputNumber.Price value={r.returnPrice || 0} onChange={v => {
                    const next: any = [...this.state.selectedProductList];
                    next[index].returnPrice = v;
                    next[index].totalAmount = new BigNumber((v || 0) as any).multipliedBy(next[index].returnCount || 0).toFixed(2)
                    this.setState({
                      selectedProductList: next
                    });
                  }}/>
                }
              },
              {
                title: '退货数量',
                align: 'right',
                width: 150,
                render: (v, r, index) => {
                  if (!r.skuId) return null;
                  return <InputNumber.Count
                    selected
                    min={1} max={r.stockCount || 0}
                    maxTip="退货数量不能大于库存数量"
                    disabled={!r.stockCount}
                    value={r.returnCount} onChange={v => {
                    const next: any = [...this.state.selectedProductList];
                    next[index].returnCount = v;
                    next[index].totalAmount = new BigNumber((v || 0) as any).multipliedBy(next[index].returnPrice || 0).toFixed(2)
                    this.setState({
                      selectedProductList: next
                    });
                  }}/>
                }
              },
              {
                title: '金额小计',
                align: 'right',
                dataIndex: 'totalAmount',
                width: 150,
              },
              // {
              //   title: '备注',
              //   dataIndex: 'remark',
              //   width: 260,
              //   render: (v, r, index) => {
              //     if (!r.skuId) return null;
              //     return <Input onChange={e => {
              //       const next: any = [...this.state.products];
              //       next[index].remark = e.target.value;
              //       this.setState({
              //         products: next
              //       });
              //     }}/>
              //   }
              // },
            ]
          }/>
        </Layout>
        <Layout.FootBar>
          <Button type="primary" onClick={this.clickOk} loading={this.state.loading} disabled={this.state.loading}>保存</Button>
        </Layout.FootBar>

        <StockProductSelectDialog
          visible={this.state.visible}
          supplierId={supplierId}
          supplierName={supplierName}
          onClose={() => this.setState({visible: false})}
          onOk={(data) => {
            this.setState((state) => {
              return {
                selectedProductList: Array.from(new Set([...data, ...state.selectedProductList, ])).map(item => {
                  return {
                    ...item,
                    returnPrice: item.price || 0,
                    returnCount: 0,
                    totalAmount: 0
                  };
                }),
              };
            });
          }}
          disabledList={row => {
            return (selectedProductList.map((i: any) => i.stockId).includes(row.stockId) || row.stockCount === 0)
          }}
        />
    </Layout.Page>)
  }

  clickOk = () => {
    if (this.state.selectedProductList.length === 0) {
      message.error('请选择退货商品');
      return;
    }
    this.form?.validateFields()
    .then(values => {
      // 检查数量
      let item: any
      for (item of this.state.selectedProductList) {
        if (Number(item.returnCount) === 0) {
          Dialog.warning({
            title: '提示',
            content: `“${item.productName}/${item.skuName}” 退货数量不能为0！`
          });
          return;
        }
      }
      this.setState({
        loading: true,
      });
      WebApi.purchase_return_order_create({
        supplierId: this.state.supplierId,
        detailList: this.state.selectedProductList.map((item: any) => {
          return {
            stockDetailId: item.stockId,
            skuName: item.skuName,
            skuId: item.skuId,
            productId: item.productId,
            productName: item.productName,
            returnCount: item.returnCount,
            returnPrice: item.returnPrice,
            repositoryId: item.repositoryId,
            repositoryName: item.repositoryName,
            price: item.price,
            remark: item.remark
          };
        }),
        remark: values.remark,
      })
      .then(() => {
        showSuccess.save();
        this.setState({
          selectedProductList: []
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      })
    })
    .catch(e => {})
  }
}
