import React, { PureComponent } from 'react';
import Dialog from '@/components/dialog/Dialog';
import ViewTable from '@/components/table/ViewTable';
import { Filter, Layout } from '@/components';
import WebApi from '@/web/api';
import { LibIStockDetail } from 'lib/interface/StockInterface';

interface IProps {
  visible: boolean;
  disabledList?: string[] | ((row: any) => boolean);
  supplierName?: string;
  supplierId?: string;
  onOk(data: LibIStockDetail[], params?: any): void;
  onClose() :void
}

interface IState {
  selectedRows: LibIStockDetail[];
}

export default class StockProductSelectDialog extends PureComponent<IProps, IState> {

  private table = React.createRef<ViewTable>()

  private reqData = {};
  
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
    };
  }

  private reset = () => {
    this.table.current?.reset();
  }

  render() {

    return <Dialog
      visible={this.props.visible}
      okButtonProps={{
        disabled: this.state.selectedRows.length === 0
      }}
      onOk={() => {
        this.props.onClose();
        this.props.onOk(this.state.selectedRows);
        this.setState({selectedRows: []});
      }}
      block
      destroyOnClose
      onCancel={this.props.onClose}
      title="选择退货商品"
    >
      <Layout flexColumn height overHidden>
        <Filter
          fields={[
            {
              name: '供应商',
              field: 'supplierId',
              type: 'renderValue',
              renderValue: () => {
                return <strong>{this.props.supplierName}</strong>
              }
            },
            {
              type: 'category',
            },
            {
              type: 'input',
              placeholder: '商品名称/条码',
              name: '搜索',
              field: 'keyword'
            }
          ]}
          onChange={(values) => {
            this.reqData = values;
            this.reset();
          }}
        />
        <ViewTable
          flex1
          ref={this.table}
          fetch={data => {
            return WebApi.stock_detail_paging({...data, ...this.reqData, supplierId: this.props.supplierId});
          }}
          rowSelection={{
            type: 'checkbox',
            columnWidth: 60,
            selectedRowKeys: this.state.selectedRows.map(item => item.stockId),
            onChange: (keys, rows) => {
              console.log(rows);
              this.setState({
                selectedRows: rows,
              })
            },
            getCheckboxProps: (record: any) => ({
              disabled: false,
            }),
          }}
          columns={columns as any[]}
          rowKey={(row: any) => row['stockId']}
        />
      </Layout>
    </Dialog>
  }
}

const columns = [
  {
    title: '商品',
    width: 200,
    dataIndex: 'productName',
  },
  {
    title: '规格',
    width: 300,
    dataIndex: 'skuName',
  },
  {
    title: '仓库',
    dataIndex: 'repositoryName',
    width: 200,
  },
  {
    title: '进价',
    dataIndex: 'price',
    align: 'right',
    width: 100,
  },
  {
    title: '供应商',
    dataIndex: 'supplierName',
    width: 200,
  },
  {
    title: '库存',
    dataIndex: 'stockCount',
    width: 100,
    align: 'right',
  },
  {
    title: '商品分类',
    dataIndex: 'categoryName',
    width: 150,
  },
]